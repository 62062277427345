<template>
  <section class="peno-overview-dashboard">
    <vue-element-loading
      v-if="!ready && currentProvince != null && !error"
      :active="!ready && currentProvince != null"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <retry v-on:retry="fetchData" v-if="!ready && error"></retry>
    <div class="container-fluid" v-if="ready && !error" id="main_content">

      <div class="row">
        <div class="col-md-8 mt-4">
          <div class="d-flex justify-content-between">
            <h4 class="font-weight-bold text-left d-flex">
              <span class="mr-3"
              >ภาพรวมจังหวัด{{ currentProvince.name_th }}</span
              >
            </h4>
            <div class="px-4 d-flex flex-row justify-content-center">
              <div>
                <router-link
                  :to="{ name: 'peno.preview' }"
                  class="btn btn-outline-red px-4 mr-3"
                >
                  ดูร่างแผนฯ
                </router-link>
              </div>
              <div>
                <router-link
                  :to="{ name: 'peno.announcement' }"
                  class="btn btn-action px-4"
                >
                  จัดทำแผน ฯ
                </router-link>
              </div>
            </div>
          </div>
          <div class="d-flex mt-3 pb-2 border-bottom menu">
            <router-link
              :to="{ name: 'peno.overview.intro' }"
              class="item mx-4 font-weight-bold link-unstyle text-ipepp"
              :class="{ active: currentTab == 'peno.overview.intro' }"
            >
              สถานะแผนปฏิบัติราชการ
            </router-link>
            <router-link
              :to="{ name: 'peno.overview.province_energy' }"
              class="item mx-4 font-weight-bold link-unstyle text-ipepp"
              :class="{ active: currentTab == 'peno.overview.province_energy' }"
            >
              ข้อมูลพลังงานจังหวัด
            </router-link>
          </div>
          <transition mode="out-in" name="fade">
            <router-view class="animated" />
          </transition>
        </div>
        <div class="col-md-4 side-shadow peno-overview-side px-0">
          <affix
            class="mt-2"
            relative-element-selector="#main_content"
            :offset="{ top: 0, bottom: 0 }"
            :scrollAffix="false"
          >
            <province-report-status
              class="pb-5"
              :nationalPlan="latestNationalPlan"
              :province="currentProvince"
            >
            </province-report-status>
          </affix>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProvinceReportStatus from '@/components/ProvinceReportStatus.vue';
import Retry from '@/components/Retry.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'penoOverviewDashboard',
  components: {
    ProvinceReportStatus,
    Retry,
  },
  data() {
    return {
      provinceSummary: null,
      currentTab: this.$route.name,
      ready: false,
      error: false,
    };
  },
  created() {
    if (!this.currentProvince) {
      this.$router.replace({name: 'peno.index'});
    }
    this.fetchData();
  },
  mounted() {
  },
  computed: {
    ...mapGetters('peno', ['currentProvince', 'allowEdit']),
    ...mapGetters([
      'nationalPlanList',
      'currentNationalPlan',
      'latestNationalPlan',
    ]),
  },
  watch: {
    $route(to, from) {
      this.currentTab = to.name;
    },
  },
  methods: {
    ...mapActions('peno', ['fetchProvinceSummary']),
    ...mapMutations(['setCurrentNationalPlan']),
    selectNationalPlanYear(option, id) {
      this.setCurrentNationalPlan(option);
    },
    fetchData() {
      if (this.currentProvince) {
        this.ready = false;
        this.error = false;
        this.fetchProvinceSummary({
          nationalPlan: this.currentNationalPlan,
          province: this.currentProvince,
        })
          .then((data) => {
            this.provinceSummary = data;
            this.ready = true;
            this.error = false;
          })
          .catch(() => {
            this.ready = false;
            this.error = true;
          });
      }
    },
    planStatusDisplay(version) {
      switch (version) {
        case 1:
          return 'ร่าง';
        case 2:
          return 'ส่งแผนครั้งที่ 1';
        case 3:
          return 'ส่งแผนครั้งที่ 2';
        default:
          return 'ไม่ระบุ';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.peno-dashboard {
  .menu {
    overflow-x: auto;
    overflow-y: hidden;

    .item.active {
      color: $red-color !important;
    }
  }
}

.year-selection {
  width: 200px;
}

.peno-overview-side {
  .affix {
    margin-top: -20px !important;
    width: 33%; // fix wide screen width
  }
}

/*.peno-overview-side {*/
/*position: relative;*/
/*}*/
</style>
